import { FIELD_PASSWORD } from '@widgets/signUp/signUpVolunteer/signUpVolunteerPrivatePerson/constants';
import * as yup from 'yup';

export const FIELD_NAME: string = 'name';
export const FIELD_REGION: string = 'region';
export const FIELD_EMAIL: string = 'email';
export const FIELD_LOCALITY: string = 'locality';
export const FIELD_STREET: string = 'street';
export const FIELD_DISTRICT: string = 'district';
export const FIELD_ABOUT: string = 'about';
export const FIELD_APPROVE: string = 'approve';
export const FIELD_PHONE: string = 'phone';
export const FIELD_SOCIAL_TAG: string = 'groups';
export const FIELD_APPLICANT_TYPE: string = 'applicantType';
export const FIELD_AVATAR: string = 'avatar';
export const FIELD_ALLOW_SHARE_PHONE: string = 'allowSharePhone';
export const FIELD_ALLOW_SHARE_EMAIL: string = 'allowShareEmail';

export const validationSchemaInNeedPrivatePerson = yup.lazy((_, options) => {
  const phoneValidationResult = options.context?.getPhoneInnerValidationResult?.();

  return yup.object().shape({
    [FIELD_NAME]: yup.string().trim().required('errors.required'),
    [FIELD_PHONE]: yup
      .string()
      .trim()
      .required('errors.required')
      .matches(/^\+[0-9]+/i, 'errors.invalid-phone-number')
      .test({
        test: () => phoneValidationResult?.isValidPhone || false,
        message: phoneValidationResult?.phoneErrorMessage || '',
      }),
    [FIELD_REGION]: yup.string().trim().required('errors.required'),
    [FIELD_LOCALITY]: yup.string().trim().required('errors.required'),
    [FIELD_STREET]: yup.string().trim().required('errors.required'),
    [FIELD_DISTRICT]: yup.string().trim().required('errors.required'),
    [FIELD_ABOUT]: yup.string().trim().required('errors.required'),
    [FIELD_APPROVE]: yup.bool().oneOf([true], 'errors.checked'),
    [FIELD_EMAIL]: yup.string().trim().email('errors.invalid-email'),
    [FIELD_PASSWORD]: yup
      .string()
      .trim()
      .required('errors.required')
      .min(6, 'errors.too-short')
      .max(20, 'errors.too-long'),
  });
});
