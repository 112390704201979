import { useMemo } from 'react';
import { useGetIconsOptimized } from './useGetIconsOptimized';

export function useGetIconOptimized(iconName: string) {
  const imagesOptimized = useGetIconsOptimized();

  return useMemo(() => {
    const image = imagesOptimized.find((v) => v.name === iconName);

    return image.content;
  }, [imagesOptimized]);
}
