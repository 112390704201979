import { yupResolver } from '@hookform/resolvers/yup';
import useMe from '@hooks/useMe';
import { useNavigate } from '@hooks/useNavigate';
import { useNotify } from '@shared/hooks/useNotify';
import { Button } from '@shared/ui/buttons/Button';
import { ErrorList } from '@shared/ui/ErrorList';
import { TextField } from '@shared/ui/TextField';
import { isApiError, translateApiError } from '@shared/utils/errors';
import { useUpdatePasswordMutation } from '@store/api/user.api';
import {
  FIELD_OLD_PASSWORD,
  FIELD_PASSWORD,
} from '@widgets/signUp/signUpVolunteer/signUpVolunteerPrivatePerson/constants';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { validationSchemaUpdatePass } from './constants';

const initialFormValues = {
  [FIELD_OLD_PASSWORD]: '',
  [FIELD_PASSWORD]: '',
};

export const UpdatePasswordForm = () => {
  const methods = useForm({
    resolver: yupResolver(validationSchemaUpdatePass),
    defaultValues: initialFormValues,
  });

  const notify = useNotify();
  const [updatePassword] = useUpdatePasswordMutation();
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const user = useMe();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { isSubmitting, isValidating },
    reset,
  } = methods;

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const onSubmit = handleSubmit(async ({ oldPassword, password }) => {
    const dto = {
      phoneNumber: user?.phoneNumber ?? '',
      oldPassword,
      password,
    };

    try {
      setApiErrors([]);
      await updatePassword(dto).unwrap();
      reset();
      notify('notifications.password-updated', {
        autoClose: 2000,
      });
    } catch (err) {
      if (isApiError(err)) {
        setApiErrors(translateApiError(err, t));
      }
      // TODO: handle bad values
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="max-w-[528px] mx-auto">
        <div className="sign-up-form-card">
          <TextField id={FIELD_OLD_PASSWORD} type="password" label="current-password" />
          <TextField id={FIELD_PASSWORD} type="password" label="new-password" className="mt-10" />

          <ErrorList errors={apiErrors} className="mt-3" />
        </div>

        <div className="flex flex-col items-center mt-8">
          <Button as="button" variant="primary" type="submit" disabled={isSubmitting || isValidating}>
            {t('continue')}
          </Button>

          <Button as="button" variant="secondary" className="mt-2" type="button" onClick={handleBackButtonClick}>
            {t('back')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
