import React, { useCallback } from 'react';
import { ToastPosition, toast, TypeOptions } from 'react-toastify';

import { DefaultToast } from '@shared/ui/toasts/DefaultToast';

interface NotifyOptions {
  autoClose?: number | false;
  position?: ToastPosition;

  variant?: TypeOptions;

  action?: string;

  onActionClick?: () => void;
}

export function useNotify() {
  const notify = useCallback((message: string | React.FC, options: NotifyOptions = {}) => {
    const { autoClose = 3000, variant = 'default', position = 'bottom-center', action, onActionClick } = options;
    const Toast = message;

    const content =
      typeof message === 'string' ? (
        <DefaultToast message={message} action={action} onActionClick={onActionClick} />
      ) : (
        <Toast />
      );

    const toastId = toast(content, {
      position,
      autoClose,
      type: variant,
      // TODO: do some refactor, and for each variant (error, success) set appropriate background color
      className: 'bg-beige shadow-snackbar py-5 px-8',
      bodyClassName: 'text-xs text-black',
      hideProgressBar: true,
    });

    return () => toast.dismiss(toastId);
  }, []);

  return notify;
}
