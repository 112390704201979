import { useMemo } from 'react';
import { ImageDataLike } from 'gatsby-plugin-image';

interface OptimizedImage {
  name: string;

  content: ImageDataLike;
}

export function useImagesOptimizedMapped(imagesData: any) {
  const images = useMemo(() => {
    if (!imagesData) return [];

    return imagesData.allFile.nodes
      .filter((image: any) => image.image)
      .map((item: any) => ({ name: item.name, content: item.image.gatsbyImageData }));
  }, [imagesData]);

  return images as OptimizedImage[];
}
