import { FIELD_APPROVE } from '@widgets/signUp/signUpInNeed/signUpInNeedPrivatePerson/constants';
import * as yup from 'yup';

export const FIELD_NAME: string = 'name';
export const FIELD_EMAIL: string = 'email';
export const FIELD_COUNTRY: string = 'country';
export const FIELD_PHONE: string = 'phone';
export const FIELD_OLD_PASSWORD: string = 'oldPassword';
export const FIELD_PASSWORD: string = 'password';
export const FIELD_AVATAR: string = 'avatar';

export const validationSchemaVolunteerPrivatePerson = yup.lazy((_, options) => {
  const phoneValidationResult = options.context?.getPhoneInnerValidationResult?.();

  return yup.object().shape({
    [FIELD_NAME]: yup.string().trim().required('errors.required'),
    [FIELD_EMAIL]: yup.string().trim().email('errors.invalid-email'),
    [FIELD_COUNTRY]: yup.string().trim().required('errors.required'),
    [FIELD_APPROVE]: yup.bool().oneOf([true], 'errors.checked'),
    [FIELD_PASSWORD]: yup
      .string()
      .trim()
      .required('errors.required')
      .min(6, 'errors.too-short')
      .max(20, 'errors.too-long'),
    [FIELD_PHONE]: yup
      .string()
      .trim()
      .required('errors.required')
      .matches(/^\+[0-9]+/i, 'errors.invalid-phone-number')
      .test({
        test: () => phoneValidationResult?.isValidPhone || false,
        message: phoneValidationResult?.phoneErrorMessage || '',
      }),
  });
});
