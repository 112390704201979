import React from 'react';
import { UpdatePasswordForm } from '@widgets/update-password/form/UpdatePasswordForm';
import { UpdatePasswordTitle } from '@widgets/update-password/page-title/UpdatePasswordTitle';

const UpdatePasswordPage = () => {
  return (
    <div className="px-4 pt-6 pb-12 lg:pt-12">
      <UpdatePasswordTitle />
      <UpdatePasswordForm />
    </div>
  );
};

export default UpdatePasswordPage;
