import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const UpdatePasswordTitle = () => {
  const { t } = useTranslation();
  const iconOptimized = useGetIconOptimized('locker');

  return (
    <div className="flex items-center md:flex-col md:justify-center">
      <GatsbyImage image={getImage(iconOptimized)} alt="locker icon" className="w-[48px] h-auto md:w-[48px]" />
      <div className="ml-4 md:ml-0 md:mt-[18px]">
        <h1 className="text-xl md:text-2xl">{t('update-password')}</h1>
      </div>
    </div>
  );
};
