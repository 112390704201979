import {
  FIELD_OLD_PASSWORD,
  FIELD_PASSWORD,
} from '@widgets/signUp/signUpVolunteer/signUpVolunteerPrivatePerson/constants';
import * as yup from 'yup';

export const validationSchemaUpdatePass = yup.object().shape({
  [FIELD_OLD_PASSWORD]: yup
    .string()
    .trim()
    .required('errors.required')
    .min(6, 'errors.too-short')
    .max(20, 'errors.too-long'),
  [FIELD_PASSWORD]: yup
    .string()
    .trim()
    .required('errors.required')
    .min(6, 'errors.too-short')
    .max(20, 'errors.too-long'),
  // .when([FIELD_OLD_PASSWORD, FIELD_PASSWORD], (FIELD_OLD_PASSWORD, schema) => {
  //   return schema.test({
  //     test: (FIELD_PASSWORD) => FIELD_PASSWORD === FIELD_OLD_PASSWORD,
  //     message: 'Test error',
  //   });
  // }),
  // .when([FIELD_OLD_PASSWORD, FIELD_PASSWORD], {
  //   is: (FIELD_OLD_PASSWORD, FIELD_PASSWORD) => FIELD_OLD_PASSWORD === FIELD_PASSWORD,
  //   then:
  // }),
});
