import { ApiError } from '@shared/types/common.types';
import { TFunction } from 'react-i18next';

export function isApiError(error: any): error is ApiError {
  return error && error.data && error.data.code && error.data.message;
}

export function translateApiError({ data }: ApiError, translateFn: TFunction<'translation', any>) {
  return data.errors
    ? data.errors.map((error) => translateFn(error.message, { param: error.param }))
    : [translateFn(data.message)];
}
