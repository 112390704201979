import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../buttons/Button';

interface DefaultToastProps {
  message: string;

  action?: string;

  onActionClick?: () => void;
}

export const DefaultToast = ({ message, action, onActionClick }: DefaultToastProps) => {
  const { t } = useTranslation();

  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="mr-5">{t(message)}</div>

      {action && (
        <div className="flex justify-end mt-5 md:mt-0">
          <Button as="button" variant="secondary" size="small" className="font-medium" onClick={onActionClick}>
            {action}
          </Button>
        </div>
      )}
    </div>
  );
};
